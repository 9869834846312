var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-row gap-4"},[_c('div',{staticClass:"w-[60%]"},[(_vm.hasConnectedHooks)?_c('table',{staticClass:"woot-table"},[_c('thead',[_vm._l((_vm.hookHeaders),function(hookHeader){return _c('th',{key:hookHeader},[_vm._v("\n          "+_vm._s(hookHeader)+"\n        ")])}),_vm._v(" "),(_vm.isHookTypeInbox)?_c('th',[_vm._v("\n          "+_vm._s(_vm.$t('INTEGRATION_APPS.LIST.INBOX'))+"\n        ")]):_vm._e()],2),_vm._v(" "),_c('tbody',_vm._l((_vm.hooks),function(hook){return _c('tr',{key:hook.id},[_vm._l((hook.properties),function(property){return _c('td',{key:property,staticClass:"break-words"},[_vm._v("\n            "+_vm._s(property)+"\n          ")])}),_vm._v(" "),(_vm.isHookTypeInbox)?_c('td',{staticClass:"break-words"},[_vm._v("\n            "+_vm._s(_vm.inboxName(hook))+"\n          ")]):_vm._e(),_vm._v(" "),_c('td',{staticClass:"button-wrapper"},[_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('INTEGRATION_APPS.LIST.DELETE.BUTTON_TEXT')),expression:"$t('INTEGRATION_APPS.LIST.DELETE.BUTTON_TEXT')",modifiers:{"top":true}}],attrs:{"variant":"smooth","color-scheme":"alert","size":"tiny","icon":"dismiss-circle","class-names":"grey-btn"},on:{"click":function($event){return _vm.$emit('delete', hook)}}})],1)],2)}),0)]):_c('p',{staticClass:"flex h-full items-center flex-col justify-center"},[_vm._v("\n      "+_vm._s(_vm.$t('INTEGRATION_APPS.NO_HOOK_CONFIGURED', {
          integrationId: _vm.integration.id,
        }))+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"w-[34%]"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.integration.name))])]),_vm._v(" "),_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
        _vm.$t(
          `INTEGRATION_APPS.SIDEBAR_DESCRIPTION.${_vm.integration.name.toUpperCase()}`,
          { installationName: _vm.globalConfig.installationName }
        )
      ),expression:"\n        $t(\n          `INTEGRATION_APPS.SIDEBAR_DESCRIPTION.${integration.name.toUpperCase()}`,\n          { installationName: globalConfig.installationName }\n        )\n      "}]})])])
}
var staticRenderFns = []

export { render, staticRenderFns }