var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1 w-full p-6 bg-white rounded-md border border-slate-50 dark:border-slate-700 dark:bg-slate-800 dark:text-slate-200"},[_c('div',{staticClass:"prose-lg max-w-5xl"},[_c('h5',{staticClass:"dark:text-slate-100"},[_vm._v("\n      "+_vm._s(_vm.$t('INTEGRATION_SETTINGS.SLACK.HELP_TEXT.TITLE'))+"\n    ")]),_vm._v(" "),_c('p',[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
          _vm.formatMessage(
            _vm.$t('INTEGRATION_SETTINGS.SLACK.HELP_TEXT.BODY', {
              selectedChannelName: _vm.selectedChannelName,
            }),
            false
          )
        ),expression:"\n          formatMessage(\n            $t('INTEGRATION_SETTINGS.SLACK.HELP_TEXT.BODY', {\n              selectedChannelName: selectedChannelName,\n            }),\n            false\n          )\n        "}]})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }