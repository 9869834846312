import { render, staticRenderFns } from "./ReplyTopPanel.vue?vue&type=template&id=4578360a&scoped=true&"
import script from "./ReplyTopPanel.vue?vue&type=script&lang=js&"
export * from "./ReplyTopPanel.vue?vue&type=script&lang=js&"
import style0 from "./ReplyTopPanel.vue?vue&type=style&index=0&id=4578360a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4578360a",
  null
  
)

export default component.exports