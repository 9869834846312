var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.shouldShowSuggestions)?_c('li',{staticClass:"label-suggestion right",on:{"mouseover":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"label-suggestion--container"},[_c('h6',{staticClass:"label-suggestion--title"},[_vm._v("Suggested labels")]),_vm._v(" "),_c('div',{staticClass:"label-suggestion--options"},[_vm._l((_vm.preparedLabels),function(label){return _c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: _vm.selectedLabels.includes(label.title)
              ? _vm.$t('LABEL_MGMT.SUGGESTIONS.TOOLTIP.DESELECT')
              : _vm.labelTooltip,
            delay: { show: 600, hide: 0 },
            hideOnClick: true,
          }),expression:"{\n            content: selectedLabels.includes(label.title)\n              ? $t('LABEL_MGMT.SUGGESTIONS.TOOLTIP.DESELECT')\n              : labelTooltip,\n            delay: { show: 600, hide: 0 },\n            hideOnClick: true,\n          }",modifiers:{"top":true}}],key:label.title,staticClass:"label-suggestion--option",on:{"click":function($event){return _vm.pushOrAddLabel(label.title)}}},[_c('woot-label',_vm._b({attrs:{"variant":"dashed","bg-color":_vm.selectedLabels.includes(label.title) ? 'var(--w-100)' : ''}},'woot-label',label,false))],1)}),_vm._v(" "),(_vm.preparedLabels.length === 1)?_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: _vm.$t('LABEL_MGMT.SUGGESTIONS.TOOLTIP.DISMISS'),
            delay: { show: 600, hide: 0 },
            hideOnClick: true,
          }),expression:"{\n            content: $t('LABEL_MGMT.SUGGESTIONS.TOOLTIP.DISMISS'),\n            delay: { show: 600, hide: 0 },\n            hideOnClick: true,\n          }",modifiers:{"top":true}}],staticClass:"label--add",attrs:{"variant":"smooth","color-scheme":_vm.isHovered ? 'alert' : 'primary',"icon":"dismiss","size":"tiny"},on:{"click":_vm.dismissSuggestions}}):_vm._e()],2),_vm._v(" "),(_vm.preparedLabels.length > 1)?_c('div',[_c('woot-button',{staticClass:"label--add",attrs:{"variant":_vm.selectedLabels.length === 0 ? 'smooth' : '',"icon":"add","size":"tiny"},on:{"click":_vm.addAllLabels}},[_vm._v("\n          "+_vm._s(_vm.addButtonText)+"\n        ")]),_vm._v(" "),_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: _vm.$t('LABEL_MGMT.SUGGESTIONS.TOOLTIP.DISMISS'),
            delay: { show: 600, hide: 0 },
            hideOnClick: true,
          }),expression:"{\n            content: $t('LABEL_MGMT.SUGGESTIONS.TOOLTIP.DISMISS'),\n            delay: { show: 600, hide: 0 },\n            hideOnClick: true,\n          }",modifiers:{"top":true}}],staticClass:"label--add",attrs:{"color-scheme":_vm.isHovered ? 'alert' : 'primary',"variant":"smooth","icon":"dismiss","size":"tiny"},on:{"click":_vm.dismissSuggestions}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"sender--info has-tooltip",attrs:{"data-original-title":"null"}},[_c('woot-thumbnail',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: _vm.$t('LABEL_MGMT.SUGGESTIONS.POWERED_BY'),
          delay: { show: 600, hide: 0 },
          hideOnClick: true,
        }),expression:"{\n          content: $t('LABEL_MGMT.SUGGESTIONS.POWERED_BY'),\n          delay: { show: 600, hide: 0 },\n          hideOnClick: true,\n        }",modifiers:{"top":true}}],attrs:{"size":"16px"}},[_c('avatar',{staticClass:"user-thumbnail thumbnail-rounded"},[_c('fluent-icon',{staticClass:"chatwoot-ai-icon",attrs:{"icon":"chatwoot-ai"}})],1)],1)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }