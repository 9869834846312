var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.parentContactName)?_c('div',{staticClass:"my-4 relative p-2.5 border rounded-[4px] text-slate-800 dark:text-slate-100 border-slate-100 dark:border-slate-700 bg-white dark:bg-slate-800"},[_c('h5',{staticClass:"text-base font-medium text-slate-900 dark:text-white"},[_vm._v("\n    "+_vm._s(_vm.$t('MERGE_CONTACTS.SUMMARY.TITLE'))+"\n  ")]),_vm._v(" "),_c('ul',{staticClass:"ml-0 list-none"},[_c('li',[_c('span',{staticClass:"inline-block mr-1"},[_vm._v("❌")]),_vm._v(" "),_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
          _vm.$t('MERGE_CONTACTS.SUMMARY.DELETE_WARNING', {
            primaryContactName: _vm.primaryContactName,
          })
        ),expression:"\n          $t('MERGE_CONTACTS.SUMMARY.DELETE_WARNING', {\n            primaryContactName,\n          })\n        "}]})]),_vm._v(" "),_c('li',[_c('span',{staticClass:"inline-block mr-1"},[_vm._v("✅")]),_vm._v(" "),_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
          _vm.$t('MERGE_CONTACTS.SUMMARY.ATTRIBUTE_WARNING', {
            primaryContactName: _vm.primaryContactName,
            parentContactName: _vm.parentContactName,
          })
        ),expression:"\n          $t('MERGE_CONTACTS.SUMMARY.ATTRIBUTE_WARNING', {\n            primaryContactName,\n            parentContactName,\n          })\n        "}]})])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }